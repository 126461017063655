<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div>
                    U heeft geen toegang to deze pagina. Is er iets niet goed? Neem dan contact op met
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a
                            style="color:#76baff"
                            target="_blank"
                            href="mailto:service@roadpursuit.com"
                            @click.stop
                            v-on="on">
                            service@roadpursuit.com
                        </a>
                        </template>
                        Opent uw mail app
                    </v-tooltip>
                    .
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: '401Unauthorized',
    mixins: [RouteMixin, MetaTagsMixin],
    data: () => ({
     
    }),
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        title: '| Geen toegang',
        meta: [
            this.getKeywords(`401 Unauthorized`),
            this.getMetaDescription(`401 Unauthorized`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`401 Unauthorized`, `401 Unauthorized`,  '', ''),
        ]
        };
    },
}
</script>
